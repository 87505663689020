import { Box, Button, TextField, Toolbar, Typography } from "@mui/material";
import { ethers } from "ethers";
import { useState, ChangeEvent, useEffect } from "react";
import { useWeb3Context } from "../common/hooks/useWeb3Context";
import { tokenAirdropAddress, metaPKaddress } from "../contract/constants";
import TokenAirdropABI from "../contract/TokenAirdropABI.json";
import MetaPKTokenABI from "../contract/MetaPKTokenABI.json";

const Main = () => {
  const { address, connect, connected, disconnect, provider } =
    useWeb3Context();

  const colorButton = connected ? "red" : "#0492c2";
  const [addressList, setAddressList] = useState<string>("");
  const [tokenAmount, setTokenAmount] = useState<string>("");

  const [approveSpender, setApproveSender] = useState<boolean>(false);

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setAddressList(event.target.value);
  };

  const handleChangeTokenAmount = (event: ChangeEvent<HTMLInputElement>) => {
    setTokenAmount(event.target.value);
  };

  const handleAddressList = (data: string) => {
    return data.replaceAll(/\s/g, "").split(",");
  };

  const handleApprove = async () => {
    const addresses = handleAddressList(addressList);
    let checkLength: number = addresses.length;
    let spendAmount = Number(tokenAmount) * checkLength;

    let convertedSpendAmount = ethers.utils.parseUnits(
      spendAmount.toString(),
      "ether"
    );

    const signer = provider.getSigner();

    const metaPKContract = new ethers.Contract(
      metaPKaddress,
      MetaPKTokenABI,
      signer
    );

    const approveTx = await metaPKContract.approve(
      tokenAirdropAddress,
      convertedSpendAmount
    );
    try {
      await approveTx.wait();
      setApproveSender(true);
      return true;
    } catch (e: any) {
      setApproveSender(false);
    }
  };

  const handleSubmit = async () => {
    const addresses = handleAddressList(addressList);
    let checkLength: number = addresses.length;
    let i: number = 0;
    let tokenAmountContainer = [];
    let convertedAmount = ethers.utils.parseUnits(tokenAmount, "ether");

    for (i; i < checkLength; i++) {
      tokenAmountContainer.push(convertedAmount);
    }

    const signer = provider.getSigner();

    const tokenAirdropContract = new ethers.Contract(
      tokenAirdropAddress,
      TokenAirdropABI,
      signer
    );

    const sendBatch = await tokenAirdropContract.sendBatch(
      addresses,
      tokenAmountContainer
    );

    const executSendBatch = await sendBatch;

    try {
      await executSendBatch.wait();
      setApproveSender(false);
      return true;
    } catch (e) {
      console.log(e);
      return false;
    }
  };

  return (
    <>
      <Toolbar />
      <Box sx={{ display: "block" }}>
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <Typography variant="h2" color="initial">
            Token Airdrop
          </Typography>
        </Box>
        <Box
          sx={{
            textAlign: "center",
          }}
        >
          <Box sx={{ p: 1 }}>
            <Button
              onClick={connected ? disconnect : connect}
              sx={{
                backgroundColor: colorButton,
                color: "white",
                fontWeight: "bold",
              }}
            >
              {connected ? "Disconnect" : "Connect"}
            </Button>
          </Box>
          <Box sx={{ p: 1 }}>
            <Typography
              variant="subtitle2"
              color="initial"
              sx={{ fontWeight: "bold" }}
            >
              {"Your address: " + address}
            </Typography>
          </Box>
        </Box>
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <TextField
            sx={{ width: "30%" }}
            label="Address List"
            multiline
            rows={10}
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              handleChange(e);
            }}
          />
        </Box>
        <Box sx={{ display: "flex", justifyContent: "center", pt: 2 }}>
          <TextField
            sx={{ width: "30%" }}
            label="Token AMount"
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              handleChangeTokenAmount(e);
            }}
          />
        </Box>
        <Box sx={{ p: 2, textAlign: "center" }}>
          <Button
            sx={{
              backgroundColor: "#0492c2",
              color: "white",
              fontWeight: "bold",
            }}
            onClick={() => {
              if (!address) {
                alert("please connect your metamask wallet first!");
              }
              if (!approveSpender) {
                handleApprove();
              } else {
                handleSubmit();
              }
            }}
          >
            {!approveSpender ? "Approve Transaction" : "Submit Airdrop"}
          </Button>
        </Box>
      </Box>
    </>
  );
};
export default Main;
