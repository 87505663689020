import binanceChain from "../../assets/chains/bsc.json";
import binanceLogo from "../../assets/chains/bsc.svg";

export interface Chain {
    name: string;
    longName: string;
    chainId: number;
    color: string;
    logo: string;
    isFavorite?: boolean;
    tokens: Token[];
    rpc: string[];
    explorers: string[];
  }
  
  export interface Token {
    id: string;
    name: string;
    address: string;
    symbol: string;
    logo: string;
    favorite?: boolean;
    isNative?: boolean;
    decimals?: number;
  }

export const BINANCE: Chain = { ...binanceChain, logo: binanceLogo };