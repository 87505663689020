import {
  responsiveFontSizes,
  createTheme,
  Theme,
  ThemeProvider,
} from "@mui/material/styles";

import { Web3ContextProvider } from "./common/hooks/useWeb3Context";
import Main from "./pages/Main";

const App = () => {
  const theme: Theme = createTheme({
    palette: {
      primary: {
        main: "#000000",
      },
      secondary: {
        main: "#223057",
      },
      background: {
        default: "#FFFFFF",
        paper: "#16254E",
      },
      text: {
        primary: "#000000",
        secondary: "#FFFFFF",
      },
      success: {
        main: "#0FC764",
      },
      error: {
        main: "#FF525C",
      },
    },
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 960,
        lg: 1280,
        xl: 1920,
      },
    },
    typography: {
      // fontFamily: "'Righteous', cursive",
      fontFamily: "'Roboto', serif;",
    },
  });
  return (
    <Web3ContextProvider>
      <ThemeProvider theme={responsiveFontSizes(theme)}>
        <Main />
      </ThemeProvider>
    </Web3ContextProvider>
  );
};

export default App;
